import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import "98.css";

import { styleReset, Window, WindowHeader, WindowContent } from "react95";

import { createGlobalStyle, ThemeProvider } from "styled-components";

import windowsStart from "./sounds/winxp.mp3";
import windowsError from "./sounds/winxp-error.mp3";
import errorImage from "./assets/error.png";

// pick a theme of your choice
import original from "react95/dist/themes/original";
// original Windows95 font (optionally)
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
// import { v4 as uuidv4 } from "uuid";
const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
`;

const allowedPasswords = ["lover", "❄️❄️❄️", "johnnylife"];

function App() {
  let navigate = useNavigate();
  const [playError] = useSound(windowsError);
  const [playStart] = useSound(windowsStart);
  const [passwordValue, setPasswordValue] = useState("");
  const [showError, setShowError] = useState(false);

  const checkLogin = () => {
    if (allowedPasswords.includes(passwordValue)) {
      navigate("/desktop");
      playStart();
      return;
    }

    setShowError(true);
    playError();
  };

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <div className="App bg-98green">
          <header className="App-header"></header>
          <main className="h-screen w-screen">
            <div className="flex justify-center items-center h-full">
              <Window className="window">
                <WindowHeader className="window-header">
                  <span className="px-4">
                    Welcome to Johnny Warströms Desktop
                  </span>
                </WindowHeader>
                <WindowContent>
                  {showError ? (
                    <div className="flex">
                      <img
                        src={errorImage}
                        alt="Logo"
                        className="w-8 h-8 mr-2"
                        style={{ marginBottom: 11 }}
                      />
                      <div class="window-body">
                        <p>
                          An error occurred - you're not invited. Sucks for you.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <label htmlFor="text18" className="text-sm mb-2">
                        Enter your secret password:
                      </label>
                      <input
                        id="text23"
                        type="password"
                        onChange={(e) => setPasswordValue(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="flex justify-end mt-4">
                    {showError ? (
                      <button onClick={() => setShowError(false)}>Retry</button>
                    ) : (
                      <button onClick={checkLogin}>Sign in</button>
                    )}
                  </div>
                </WindowContent>
              </Window>
            </div>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
