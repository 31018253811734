import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  styleReset,
  AppBar,
  Toolbar,
  TextField,
  Button,
  List,
  ListItem,
  Divider,
  Window,
  WindowHeader,
  WindowContent,
  Panel,
} from "react95";

import useSound from "use-sound";

import { createGlobalStyle, ThemeProvider } from "styled-components";

import folderImage from "./assets/folder.png";
import mentiStrategy from "./assets/menti-strategy.png";
import msnMessenger from "./assets/msn.png";
import hannah from "./assets/hannah.png";
import windowsImage from "./assets/windows.png";
import msAgent from "./assets/msagent.png";
import internetExplorer from "./assets/internet.png";
import photoIcon from "./assets/photos.png";
import noteIcon from "./assets/note.png";
import inspoIcon from "./assets/inspo.png";

import one from "./assets/1.jpg";
import two from "./assets/2.jpg";
import three from "./assets/3.jpg";
import four from "./assets/4.jpg";
import five from "./assets/5.jpg";
import six from "./assets/6.png";
import seven from "./assets/7.jpg";
import eight from "./assets/8.jpg";
import nine from "./assets/9.jpg";
import ten from "./assets/10.jpg";
import eleven from "./assets/11.jpg";
import twelve from "./assets/12.jpg";
import thirteen from "./assets/13.jpg";
import fourteen from "./assets/14.jpg";
import fifteen from "./assets/15.jpg";
import sixteen from "./assets/16.jpg";
import seventeen from "./assets/17.jpg";
import adam from "./assets/adam.png";
import bezos from "./assets/bezos.jpeg";
import elon from "./assets/elon.png";
import holmes from "./assets/holmes.jpeg";

import modemSound from "./sounds/modem.mp3";
import windowsError from "./sounds/winxp-error.mp3";

// pick a theme of your choice
import original from "react95/dist/themes/original";
// original Windows95 font (optionally)
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
// import { v4 as uuidv4 } from "uuid";
const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
`;

function Desktop() {
  let navigate = useNavigate();

  const [openDocument, setOpenDocument] = useState(false);
  const [openInternet, setOpenInternet] = useState(false);
  const [openMsn, setOpenMsn] = useState(false);
  const [openPhotos, setOpenPhotos] = useState(false);
  const [openPersonalNote, setOpenPersonalNote] = useState(false);
  const [openInspo, setOpenInspo] = useState(false);

  const [open, setOpen] = useState(false);
  const [showMessengerError, setShowMessengerError] = useState(false);
  const [playError] = useSound(windowsError);
  const [playModem] = useSound(modemSound);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <div className="App bg-98green">
          <AppBar>
            <Toolbar style={{ justifyContent: "space-between" }}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <Button
                  onClick={() => setOpen(!open)}
                  active={open}
                  style={{ fontWeight: "bold" }}
                >
                  <img
                    src={windowsImage}
                    alt="react95 logo"
                    style={{ height: "20px", marginRight: 4 }}
                  />
                  Start
                </Button>
                {open && (
                  <List
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "100%",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    <ListItem disabled>
                      This will be live & updated forever
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => navigate("/")}>Logout</ListItem>
                  </List>
                )}
              </div>

              <TextField placeholder="Search..." width={150} />
            </Toolbar>
          </AppBar>
          <main className="h-screen w-screen p-4 mt-12">
            <div className="flex flex-col items-start">
              <button
                onDoubleClick={() => setOpenDocument(true)}
                className="bg-transparent"
                style={{
                  boxShadow: "none",
                  padding: 2,
                  marginBottom: 16,
                  width: 120,
                }}
              >
                <div className="flex flex-col items-center cursor-pointer p-1">
                  <img
                    src={folderImage}
                    alt="Logo"
                    className="w-12 h-12 mb-2"
                  />
                  <p className="font-windows text-md text-white">
                    Menti Manifesto 2022
                  </p>
                </div>
              </button>
              <button
                onDoubleClick={() => setOpenMsn(true)}
                className="bg-transparent"
                style={{
                  boxShadow: "none",
                  padding: 2,
                  marginBottom: 16,
                  width: 120,
                }}
              >
                <div className="flex flex-col items-center cursor-pointer p-1">
                  <img
                    src={msnMessenger}
                    alt="Logo"
                    className="w-12 h-12 mb-2"
                  />
                  <p className="font-windows text-md text-white">
                    MSN Messenger
                  </p>
                </div>
              </button>
              <button
                onDoubleClick={() => {
                  setOpenInternet(true);
                  playModem();
                }}
                className="bg-transparent"
                style={{
                  boxShadow: "none",
                  padding: 2,
                  marginBottom: 16,
                  width: 120,
                }}
              >
                <div className="flex flex-col items-center cursor-pointer p-1">
                  <img
                    src={internetExplorer}
                    alt="Logo"
                    className="w-12 h-12 mb-2"
                  />
                  <p className="font-windows text-md text-white">
                    Internet Explorer
                  </p>
                </div>
              </button>
              <button
                onDoubleClick={() => setOpenPhotos(true)}
                className="bg-transparent"
                style={{
                  boxShadow: "none",
                  padding: 2,
                  marginBottom: 16,
                  width: 120,
                }}
              >
                <div className="flex flex-col items-center cursor-pointer p-1">
                  <img src={photoIcon} alt="Logo" className="w-12 h-12 mb-2" />
                  <p className="font-windows text-md text-white">Photos</p>
                </div>
              </button>
              <button
                onDoubleClick={() => setOpenInspo(true)}
                className="bg-transparent"
                style={{
                  boxShadow: "none",
                  padding: 2,
                  marginBottom: 16,
                  width: 120,
                }}
              >
                <div className="flex flex-col items-center cursor-pointer p-1">
                  <img src={inspoIcon} alt="Logo" className="w-12 h-12 mb-2" />
                  <p className="font-windows text-md text-white">My Inspo</p>
                </div>
              </button>
              <button
                onDoubleClick={() => setOpenPersonalNote(true)}
                className="bg-transparent"
                style={{
                  boxShadow: "none",
                  padding: 2,
                  marginBottom: 16,
                  width: 120,
                }}
              >
                <div className="flex flex-col items-center cursor-pointer p-1">
                  <img src={noteIcon} alt="Logo" className="w-12 h-12 mb-2" />
                  <p className="font-windows text-md text-white">
                    A message from your friends
                  </p>
                </div>
              </button>
            </div>

            {openDocument ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-2">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <Window className="window">
                      <WindowHeader className="window-header text-sm">
                        <>
                          <div className="flex items-center">
                            [Draft] Mentimeter Manifesto | Confidential
                          </div>
                        </>
                      </WindowHeader>
                      <WindowContent>
                        <p className="text-md mb-2">
                          2022 is the year of leaning in. In recent years we
                          have achieved success those underneath me can only
                          dream of. In this turbulent macro-economic situation
                          we need to ask ourselves the tough questions: “Does it
                          scale?” - an answer that is often asked but rarely
                          answered. We must zig when others zag, and not
                          underinvest in what we know works. We will continue to
                          stock the fridges with cold lagers, hold CULTure close
                          to our heart, and deliver our product, enterprise and
                          company strategies with the cadence we know. Fast.
                        </p>
                        <p className="text-md mb-4">
                          Look, we’re huge - but not huge enough. I know we can
                          do this together - and with your efforts, the end goal
                          is near - having SoftBank as majority stakeholders and
                          strategic partners. Let's make the world a better
                          place - one menti at a time.
                        </p>
                        <img
                          src={mentiStrategy}
                          alt="Logo"
                          className="px-4 my-4 w-96"
                        />
                        <p className="text-md mb-4 text-lg">
                          Signing off for now,
                          <br /> <span className="text-xl">J Warström</span>
                        </p>
                        <div className="flex mt-4 justify-end">
                          <button
                            onClick={() => {
                              setOpenDocument(false);
                            }}
                            className="text-md"
                          >
                            Close
                          </button>
                        </div>
                      </WindowContent>
                    </Window>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {openMsn ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-2">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <Window className="window">
                      <WindowHeader className="window-header text-sm">
                        <>
                          <div className="flex items-center">
                            <img
                              src={msnMessenger}
                              alt="Logo"
                              className="w-4 h-4 mr-1"
                            />
                            <span>MSN Messenger | 1 unread message</span>
                          </div>
                        </>
                      </WindowHeader>
                      <WindowContent>
                        {showMessengerError ? (
                          <div className="flex">
                            <img
                              src={msAgent}
                              alt="Logo"
                              className="w-8 h-8 mr-2"
                              style={{ marginBottom: 11 }}
                            />
                            <div className="window-body">
                              <p>
                                Hands off the keyboard, Johnny - you're a bit
                                too old to be messaging with MSN.
                              </p>
                              <div className="flex mt-4 justify-end">
                                <button
                                  onClick={() => {
                                    setOpenMsn(false);
                                    setShowMessengerError(false);
                                  }}
                                  className="text-md"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="field-row-stacked mb-4">
                              <div className="flex items-center justify-start">
                                <p className="text-xs mr-1 mt-2">Status:</p>
                                <select className="mt-0 pl-2">
                                  <option>Online</option>
                                  <option>Offline</option>
                                </select>
                              </div>
                            </div>
                            <Panel variant="well" className="mb-4">
                              <div className="field-row-stacked bg-white w-96 p-2">
                                <div className="flex items-center">
                                  <img
                                    src={hannah}
                                    alt="Logo"
                                    className="w-6 h-6 rounded-full mr-2"
                                  />
                                  <p className="mb-2">
                                    <span className="text-blue-500 text-sm">
                                      HannahFromSöder
                                    </span>{" "}
                                    wrote:{" "}
                                  </p>
                                </div>
                                <p className="my-2 text-sm">
                                  10:36pm - Hey good lookin' let's meet up irl -
                                  A/S/L?
                                </p>
                              </div>
                            </Panel>
                            <div className="w-full">
                              <div className="flex flex-col mb-2">
                                <label className="mb-1" htmlFor="text18">
                                  <p>Write your reply:</p>
                                </label>
                                <textarea
                                  id="text23"
                                  type="text"
                                  className="h-12 mb-2 text-lg"
                                />
                              </div>
                              <div
                                className="field-row"
                                style={{ justifyContent: "right" }}
                              >
                                <button
                                  onClick={() => {
                                    setShowMessengerError(true);
                                    playError();
                                  }}
                                  className="text-md"
                                >
                                  Send message
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </WindowContent>
                    </Window>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {openInternet ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-2">
                  <div className="relative w-auto my-6">
                    <Window className="window">
                      <WindowHeader className="window-header text-sm">
                        <>
                          <div className="flex items-center">
                            Internet Explorer | Kemtvättsguiden.se
                          </div>
                        </>
                      </WindowHeader>
                      <WindowContent>
                        <iframe
                          title="internet explorer"
                          src="https://kemtvattsguiden.se/"
                          // className="max-w-3xl max-h-3xl"
                          width={1200}
                          height={600}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
                        ></iframe>
                        <div className="flex mt-4 justify-end">
                          <button
                            onClick={() => {
                              setOpenInternet(false);
                            }}
                            className="text-md"
                          >
                            Close
                          </button>
                        </div>
                      </WindowContent>
                    </Window>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {openPhotos ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-2">
                  <div className="relative w-auto my-6 mx-auto ">
                    <Window className="window">
                      <WindowHeader className="window-header text-sm">
                        <>
                          <div className="flex items-center">Your Photos</div>
                        </>
                      </WindowHeader>
                      <WindowContent>
                        <Panel variant="well">
                          <div className="grid grid-cols-8 justify-between bg-white p-1">
                            <img
                              src={one}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={two}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={three}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={four}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={five}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={six}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={seven}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={eight}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={nine}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={ten}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={eleven}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={twelve}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={thirteen}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={fourteen}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={fifteen}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={sixteen}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={seventeen}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                          </div>
                        </Panel>
                        <div className="flex mt-4 justify-end">
                          <button
                            onClick={() => {
                              setOpenPhotos(false);
                            }}
                            className="text-md"
                          >
                            Keep files
                          </button>
                        </div>
                      </WindowContent>
                    </Window>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {openPersonalNote ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-2">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <Window className="window">
                      <WindowHeader className="window-header text-sm">
                        <>
                          <div className="flex items-center">
                            A message from your friends
                          </div>
                        </>
                      </WindowHeader>
                      <WindowContent>
                        <p className="text-xl mb-2">
                          Happiest 40th birthday to you Johnny - a day to
                          remember.
                        </p>
                        <p className="text-md mb-2">
                          We sat to write you this message finding photos of
                          experiences we've shared together - how amazing,
                          terrifying and fucking stupid some of them have been.
                          But NEVER boring.
                        </p>
                        <p className="text-md mb-2">
                          We've laughed, cried, blacked-out and nearly died in
                          more countries and continents than most. We've been on
                          the same page, burnt the book before reading, looked
                          after each other, and been left for dead. We've taken
                          the easy path and the one less travelled together.
                        </p>
                        <p className="text-md mb-2">
                          But the one common denominator in both of our lives -
                          and some of the best times we've had, is you.
                        </p>
                        <p className="text-md mb-8">
                          Happiest of days you magnificent bastard - we're
                          incredibly grateful to have you as a friend.
                        </p>
                        <p className="text-md mb-2">
                          Lots of love, Johanna & Geordi {"<3"}
                        </p>
                        <div className="flex mt-4 justify-end">
                          <button
                            onClick={() => {
                              setOpenPersonalNote(false);
                            }}
                            className="text-md"
                          >
                            Close
                          </button>
                        </div>
                      </WindowContent>
                    </Window>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {openInspo ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-2">
                  <div className="relative w-auto my-6 mx-auto ">
                    <Window className="window">
                      <WindowHeader className="window-header text-sm">
                        <>
                          <div className="flex items-center">
                            My Inspiration
                          </div>
                        </>
                      </WindowHeader>
                      <WindowContent>
                        <Panel variant="well">
                          <div className="grid grid-cols-8 justify-between bg-white p-1">
                            <img
                              src={adam}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={bezos}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={elon}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                            <img
                              src={holmes}
                              alt="Logo"
                              className="h-48 w-48 m-1"
                            />
                          </div>
                        </Panel>
                        <div className="flex mt-4 justify-end">
                          <button
                            onClick={() => {
                              setOpenInspo(false);
                            }}
                            className="text-md"
                          >
                            Keep files
                          </button>
                        </div>
                      </WindowContent>
                    </Window>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Desktop;
